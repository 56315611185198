import { CommonModule } from "@angular/common";
import { Component, inject, OnDestroy, signal } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { RoamButtonComponent } from "../../button/roam-button/roam-button.component";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectChange, MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { IAssociation } from "@app/shared/interfaces";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { InlineSVGModule } from "ng-inline-svg-2";
import { MatButtonModule } from "@angular/material/button";
import { toSignal } from "@angular/core/rxjs-interop";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RoamButtonComponent,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    InlineSVGModule,
    MatButtonModule,
  ],
  selector: "app-dialog-select-association",
  templateUrl: "./dialog-association.component.html",
  styleUrls: ["./dialog-association.component.scss"],
})
export class DialogSelectAssociationComponent implements OnDestroy {
  public icon = iconLib;
  #destroy = new Subject<void>();
  readonly userConfig = inject(UserConfigStore);
  readonly dialogRef = inject(MatDialogRef<DialogSelectAssociationComponent>);
  next() {
    this.userConfig.selectAssociation(this.localSelectedAssociation);
    this.dialogRef.close(true);
  }
  close() {
    this.dialogRef.close(false);
  }

  readonly searchAssociationForm = new FormControl<string | null>(null);
  readonly #selectedAssociation = signal<IAssociation | null>(null);
  readonly association = this.#selectedAssociation.asReadonly();
  readonly allAssociationSignal = toSignal(this.userConfig.associations$);
  readonly filteredAssociations$ = this.searchAssociationForm.valueChanges.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    startWith(this.searchAssociationForm.value),
    switchMap(term => {
      return this.userConfig.associations$.pipe(
        map(list => {
          if (!term) return list;
          return list.filter(
            x =>
              x.name.toLowerCase().includes(term?.toLowerCase()) &&
              x.id != "all"
          );
        })
      );
    })
  );

  localSelectedAssociation = "";
  localSelectedAssociationName = "";
  selectAssociation(e: MatSelectChange): void {
    this.localSelectedAssociation = e.value;
    this.localSelectedAssociationName =
      this.allAssociationSignal()?.find(x => x.id === e.value)?.name || "";
  }

  ngOnInit(): void {
    merge(
      this.userConfig.selectedAssociation$.pipe(
        distinctUntilChanged(),
        tap(association => {
          this.#selectedAssociation.set(association || null);
        })
      )
    )
      .pipe(takeUntil(this.#destroy))
      .subscribe();
  }

  ngOnDestroy() {
    this.#destroy.next();
    this.#destroy.complete();
  }
}
