export enum AttachmentType {
  BILL = "bills",
  BILL_PAYMENTS = "bill_payments",
  CREDIT_MEMO = "credit_memos",
  INVOICE = "invoices",
  RECEIVED_PAYMENTS = "received_payments",
  STATEMENT = "statements",
  VIOLATION = "violations",
  REQUEST = "requests",
  MOTION = "motions",
  TASK = "tasks",
  CONTACT = "contacts"
}
